export default [
  {
    path: '/painel/termos',
    name: 'list-terms',
    component: () =>
      import(
        /* webpackChunkName: "list-terms" */ '../../views/Dashboard/Term/ListTerm'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },

  {
    path: '/painel/termos-aceitos',
    name: 'single-term',
    component: () =>
      import(
        /* webpackChunkName: "single-term" */ '../../views/Dashboard/Term/SingleTerm'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },

  {
    path: '/painel/termos/criar',
    name: 'create-term',
    component: () =>
      import(
        /* webpackChunkName: "create-term" */ '../../views/Dashboard/Term/CreateTerm'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },

  {
    path: '/painel/termos/:id/editar',
    name: 'edit-term',
    component: () =>
      import(
        /* webpackChunkName: "edit-term" */ '../../views/Dashboard/Term/EditTerm'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props: true
  },
  {
    path: '/painel/termos/:id/aceites',
    name: 'list-accept',
    component: () =>
      import(
        /* webpackChunkName: "edit-term" */ '../../views/Dashboard/Term/ListAccept'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props: true
  }
];
