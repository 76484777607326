export default [
  {
    path: '/painel/regionais',
    name: 'list-regionals',
    component: () =>
      import(
        /* webpackChunkName: "list-regionals" */ '../../views/Dashboard/Regional/Index'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/regionais/criar',
    name: 'create-regional',
    component: () =>
      import(
        /* webpackChunkName: "create-regional" */ '../../views/Dashboard/Regional/Create'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/regionais/:id',
    name: 'edit-regional',
    component: () =>
      import(
        /* webpackChunkName: "edit-regional" */ '../../views/Dashboard/Regional/Edit'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id)
      };
    }
  },
  {
    path: '/painel/regionais/:id/gerenciar-acesso',
    name: 'manage-regional-access',
    component: () =>
      import(
        /* webpackChunkName: "manage-regional-access" */ '../../views/Dashboard/Regional/ManageAccess'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id)
      };
    }
  },
  {
    path: '/painel/regionais/:id/criar-acesso',
    name: 'create-regional-access',
    component: () =>
      import(
        /* webpackChunkName: "create-regional-access" */ '../../views/Dashboard/Regional/CreateAccess'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id)
      };
    }
  },
  {
    path: '/painel/regionais/:id/editar-acesso/:userId',
    name: 'edit-regional-access',
    component: () =>
      import(
        /* webpackChunkName: "edit-regional-access" */ '../../views/Dashboard/Regional/EditAccess'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id),
        userId: parseInt(route.params.userId)
      };
    }
  }
];
