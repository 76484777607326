import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import courses from './modules/courses';
import terms from './modules/terms';
import register from './modules/register';
import menu from './modules/menu';

Vue.use(Vuex);

Vue.config.devtools = process.env.VUE_APP_VUE_PLUGIN;

export default new Vuex.Store({
  state: {
    search: '',
    course: '',
    Experience: '',
    brand: '',
    education_level: '',
    gender: '',
    min_age: '',
    max_age: '',
    cities: [],
    participant_name: '',
    year: '',
    complementary_curriculum: ''
  },

  getters: {},

  mutations: {
    setSearch(state, search) {
      state.search = search;
    },
    setCourse(state, course) {
      state.course = course;
    },
    setExperience(state, Experience) {
      state.Experience = Experience;
    },
    setBrand(state, brand) {
      state.brand = brand;
    },
    setEducationLevel(state, educationLevel) {
      state.education_level = educationLevel;
    },
    setGender(state, gender) {
      state.gender = gender;
    },
    setMinAge(state, age) {
      state.min_age = age;
    },
    setMaxAge(state, age) {
      state.max_age = age;
    },
    setCities(state, cities) {
      state.cities = cities;
    },
    setParticipantName(state, participantName) {
      state.participant_name = participantName;
    },
    setYear(state, year) {
      state.year = year;
    },
    setComplementaryCurriculum(state, complementaryCurriculum) {
      state.complementary_curriculum = complementaryCurriculum;
    },

    clearFields(state) {
      state.search = '';
      // state.course = '';
      state.Experience = '';
      state.brand = '';
      state.education_level = '';
      state.gender = '';
      state.min_age = '';
      state.max_age = '';
      state.cities = [];
      state.participant_name = '';
      state.year = '';
      state.complementary_curriculum = '';
    }
  },

  actions: {},

  modules: {
    auth,
    courses,
    terms,
    register,
    menu
  }
});
