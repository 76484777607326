import axios from '@/utils/axios';

export default {
  namespaced: true,

  state: {
    items: [],
    pagination: {
      current_page: null,
      from: null,
      links: [],
      last_page: null,
      per_page: null,
      to: null,
      total: null
    }
  },

  getters: {},

  mutations: {
    setCourses(state, courses) {
      state.items = courses;
    },
    setPaginationLinks(state, links) {
      state.pagination.links = links;
    },
    setPaginationAttributes(state, meta) {
      state.pagination.current_page = meta.current_page;
      state.pagination.from = meta.from;
      state.pagination.last_page = meta.last_page;
      state.pagination.per_page = meta.per_page;
      state.pagination.to = meta.to;
      state.pagination.total = meta.total;
    }
  },

  actions: {
    async fetchCourses({ commit }, endpoint = '/cursos') {
      await new Promise((resolve, reject) => {
        axios
          .get(endpoint)
          .then(response => {
            const courses = response.data.data.map(course => {
              return {
                id: course.id,
                ...course.attributes
              };
            });

            const links = response.data.meta.links.map(link => {
              let url = link.url != null ? new URL(link.url) : null;

              return {
                url:
                  url != null ? url.pathname.substring(4) + url.search : null,
                label: link.label,
                active: link.active,
                disabled: link.url == null || link.active ? 'disabled' : null
              };
            });

            commit('setCourses', courses);
            commit('setPaginationAttributes', response.data.meta);
            commit('setPaginationLinks', links);

            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    async filterCourses({ commit }, data) {
      await new Promise((resolve, reject) => {
        axios
          .post(data.endpoint, data.fields)
          .then(response => {
            const courses = response.data.data.map(course => {
              return {
                id: course.id,
                ...course.attributes
              };
            });

            const links = response.data.meta.links.map(link => {
              let url = link.url != null ? new URL(link.url) : null;

              return {
                url:
                  url != null ? url.pathname.substring(4) + url.search : null,
                label: link.label,
                active: link.active,
                disabled: link.url == null || link.active ? 'disabled' : null
              };
            });

            commit('setCourses', courses);
            commit('setPaginationAttributes', response.data.meta);
            commit('setPaginationLinks', links);

            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};
