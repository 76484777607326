<template>
  <div class="flex items-center VueTables__search-field">
    <label :for="`VueTables__search_${props.id}`" class="mr-3">
      {{ props.display('filter') }}
    </label>

    <input
      class="rounded-md p-2 outline-none VueTables__search__input"
      type="text"
      ref="filter"
      :placeholder="props.display('filterPlaceholder')"
      @keyup="e => props.search(props.opts.debounce)(e)"
      :id="`VueTables__search_${props.id}`"
      autocomplete="off"
    />
  </div>
</template>

<script>
export default {
  name: 'CustomGenericFilter',
  props: ['props'],
  methods: {
    focus() {
      this.$refs.filter.focus();
    },
    blur() {
      this.$refs.filter.blur();
    }
  }
};
</script>
