import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
});

instance.defaults.withCredentials = true;

instance.interceptors.request.use(
  function(config) {
    config.validateStatus = status => {
      return status >= 200 && status <= 302;
    };

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default instance;
