<template>
  <div class="flex items-center VueTables__limit-field">
    <label class="mr-3" :for="`VueTables__limit_${props.id}`">
      {{ props.display('limit') }}
    </label>
    <select
      :id="props.selectAttrs.id"
      class="rounded-md p-2 outline-none"
      :class="props.selectAttrs.class"
      @change="props.selectEvents.change"
    >
      <option
        v-for="val in props.perPageValues"
        :key="val"
        :value="val"
        :selected="val === props.selectAttrs.value"
        >{{ val }}</option
      >
    </select>
  </div>
</template>

<script>
export default {
  name: 'CustomPerPageSelector',
  props: ['props']
};
</script>
