<template>
  <div id="app" class="font-sans max-h-screen">
    <vue-confirm-dialog></vue-confirm-dialog>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    this.$nextTick(() => {
      const rd = document.createElement('script')

      rd.setAttribute('type', 'text/javascript')
      rd.async = true
      rd.setAttribute('src', 'https://d335luupugsy2.cloudfront.net/js/loader-scripts/245ceb6a-175f-4318-b5c8-5c14220e31d0-loader.js')

      document.body.appendChild(rd)
    })
  }
};
</script>
