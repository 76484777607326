<template>
  <td :tabindex="props.tabIndex" :class="props.classes" class="px-2 py-1">
    <vnodes :vnodes="props.content" />
  </td>
</template>

<script>
export default {
  name: 'MyTableCell',
  props: ['props'],
  components: {
    vnodes: {
      functional: true,
      render: (h, ctx) =>
        typeof ctx.props.vnodes === 'object'
          ? ctx.props.vnodes
          : [ctx.props.vnodes]
    }
  }
};
</script>

<style scoped></style>
