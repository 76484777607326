export default [
  {
    path: '/painel/associados',
    name: 'list-associates',
    component: () =>
      import(
        /* webpackChunkName: "list-associates" */ '../../views/Dashboard/Associate/Index'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/associados/criar',
    name: 'create-associate',
    component: () =>
      import(
        /* webpackChunkName: "create-associate" */ '../../views/Dashboard/Associate/Create'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/associados/:id/editar',
    name: 'edit-associate',
    component: () =>
      import(
        /* webpackChunkName: "edit-associate" */ '../../views/Dashboard/Associate/Edit'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id)
      };
    }
  },
  {
    path: '/painel/associados/:id/gerenciar-acesso',
    name: 'manage-associate-access',
    component: () =>
      import(
        /* webpackChunkName: "manage-associate-access" */ '../../views/Dashboard/Associate/ManageAccess'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id)
      };
    }
  },
  {
    path: '/painel/associados/:id/criar-acesso',
    name: 'create-associate-access',
    component: () =>
      import(
        /* webpackChunkName: "create-associate-access" */ '../../views/Dashboard/Associate/CreateAccess'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id)
      };
    }
  },
  {
    path: '/painel/associados/:id/editar-acesso/:userId',
    name: 'edit-associate-access',
    component: () =>
      import(
        /* webpackChunkName: "edit-associate-access" */ '../../views/Dashboard/Associate/EditAccess'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id),
        userId: parseInt(route.params.userId)
      };
    }
  }
];
