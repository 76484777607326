import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/css/main.css';
import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import VueRangeSlider from 'vue-range-component';
import 'vue-range-component/dist/vue-range-slider.css';
import VueFormWizard from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import '@/assets/css/themify-icons.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import Vuelidate from 'vuelidate';
import titleMixin from './mixins/titleMixin';
import { abilitiesPlugin } from '@casl/vue';
import ability from './services/ability';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { ClientTable } from 'vue-tables-2';
import CustomDataTable from './components/CustomDataTable';
import CustomGenericFilter from './components/CustomGenericFilter';
import CustomPerPageSelector from './components/CustomPerPageSelector';
import CustomPagination from './components/CustomPagination';
import { VTooltip } from 'v-tooltip';
import CustomTable from './components/CustomTable';
import CustomTableHead from './components/CustomTableHead';
import CustomTableHeading from './components/CustomTableHeading';
import CustomTableCell from './components/CustomTableCell';
import CustomHeadingsRow from './components/CustomHeadingsRow';
import CustomTableRow from './components/CustomTableRow';
import ToggleButton from 'vue-js-toggle-button';
import 'remixicon/fonts/remixicon.css';
import VueGtag from 'vue-gtag';
import VModal from 'vue-js-modal';
import VueMask from 'v-mask';
import VueConfirmDialog from 'vue-confirm-dialog';
import SsrCarousel from 'vue-ssr-carousel';
import 'vue-ssr-carousel/index.css';

Vue.use(
  VueGtag,
  {
    pageTrackerExcludedRoutes: [
      'home',
      'about',
      'how-it-works',
      'login',
      'register',
      'register-confirmation',
      'recovery',
      'reset'
    ],
    config: { id: process.env.VUE_APP_GTAG_ID }
  },
  router
);

Vue.use(VueFormWizard);
Vue.use(VueToast);
Vue.use(Vuelidate);
Vue.use(abilitiesPlugin, ability);
Vue.use(ClientTable, {}, false, 'bootstrap4', {
  dataTable: CustomDataTable,
  genericFilter: CustomGenericFilter,
  perPageSelector: CustomPerPageSelector,
  pagination: CustomPagination,
  table: CustomTable,
  tableHead: CustomTableHead,
  tableHeading: CustomTableHeading,
  tableCell: CustomTableCell,
  headingsRow: CustomHeadingsRow,
  tableRow: CustomTableRow
});
Vue.use(ToggleButton);

Vue.use(VModal);
Vue.use(VueMask);
Vue.use(VueConfirmDialog);

Vue.directive('tooltip', VTooltip);

Vue.config.productionTip = false;
Vue.config.devtools = process.env.VUE_APP_VUE_PLUGIN;

Vue.component('multiselect', Multiselect);
Vue.component('vue-select', VueSelect);
Vue.component('vue-range-slider', VueRangeSlider);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
Vue.component('ssr-carousel', SsrCarousel);

Vue.mixin(titleMixin);

store.dispatch('auth/user').finally(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
});
