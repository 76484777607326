export default {
  namespaced: true,

  state: {
    expanded: true
  },

  getters: {
    expanded(state) {
      return state.expanded;
    }
  },

  mutations: {
    SET_EXPANDED(state, expanded) {
      state.expanded = expanded;
    }
  },

  actions: {
    toggle({ commit, state }) {
      commit('SET_EXPANDED', !state.expanded);
    }
  }
};
