import term from './modules/term';
import associate from './modules/associate';
import regional from './modules/regional';

const dashboardRoutes = [
  {
    path: '/painel',
    name: 'dashboard',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/Index'),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/buscar',
    name: 'search',
    component: () =>
      import(/* webpackChunkName: "search" */ '../views/Dashboard/Search'),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/resultados',
    name: 'search-results',
    component: () =>
      import(
        /* webpackChunkName: "search-results" */ '../views/Dashboard/SearchResults'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/outros-resultados',
    name: 'search-other-results',
    component: () =>
      import(
        /* webpackChunkName: "search-other-results" */ '../views/Dashboard/SearchOtherResults'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/curriculum/:id',
    name: 'curriculum',
    component: () =>
      import(
        /* webpackChunkName: "curriculum" */ '../views/Dashboard/Search/Curriculum'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props: true
  },
  {
    path: '/painel/cursos',
    name: 'course',
    component: () =>
      import(
        /* webpackChunkName: "integration" */ '../views/Dashboard/Course/Index'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/cursos/editar/:id',
    name: 'edit-course',
    component: () =>
      import(
        /* webpackChunkName: "edit-course" */ '../views/Dashboard/Course/Edit'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props: true
  },
  {
    path: '/painel/sindicatos',
    name: 'list-syndicates',
    component: () =>
      import(
        /* webpackChunkName: "list-syndicates" */ '../views/Dashboard/Syndicate/Index'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/sindicatos/criar',
    name: 'create-syndicate',
    component: () =>
      import(
        /* webpackChunkName: "create-syndicate" */ '../views/Dashboard/Syndicate/Create'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/sindicatos/:id',
    name: 'edit-syndicate',
    component: () =>
      import(
        /* webpackChunkName: "edit-syndicate" */ '../views/Dashboard/Syndicate/Edit'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id)
      };
    }
  },
  {
    path: '/painel/sindicatos/:id/gerenciar-acesso',
    name: 'manage-syndicate-access',
    component: () =>
      import(
        /* webpackChunkName: "manage-syndicate-access" */ '../views/Dashboard/Syndicate/ManageAccess'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id)
      };
    }
  },
  {
    path: '/painel/sindicatos/:id/criar-acesso',
    name: 'create-syndicate-access',
    component: () =>
      import(
        /* webpackChunkName: "create-syndicate-access" */ '../views/Dashboard/Syndicate/CreateAccess'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id)
      };
    }
  },
  {
    path: '/painel/sindicatos/:id/editar-acesso/:userId',
    name: 'edit-syndicate-access',
    component: () =>
      import(
        /* webpackChunkName: "edit-syndicate-access" */ '../views/Dashboard/Syndicate/EditAccess'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id),
        userId: parseInt(route.params.userId)
      };
    }
  },
  {
    path: '/painel/criar-vaga',
    name: 'register-vacancy',
    component: () =>
      import(
        /* webpackChunkName: "register-vacancy" */ '../views/Dashboard/Vacancy/Create'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/participantes-inscritos/:id',
    name: 'subscrible-participants',
    component: () =>
      import(
        /* webpackChunkName: "subscrible-participants" */ '../views/Dashboard/Vacancy/SubscribleParticipants'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/inscrever-participante/:id',
    name: 'applying-participant',
    component: () =>
      import(
        /* webpackChunkName: "applying-participant" */ '../views/Dashboard/Vacancy/ApplyingParticipant'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/selecionar-candidatos/:id',
    name: 'select-participant',
    component: () =>
      import(
        /* webpackChunkName: "select-participant" */ '../views/Dashboard/Vacancy/SelectParticipant'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/agendar-entrevista/:id',
    name: 'schedule-interview',
    component: () =>
      import(
        /* webpackChunkName: "schedule-interview" */ '../views/Dashboard/Vacancy/ScheduleInterview'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/entrevista/:tipo/:vacancy/:participant',
    name: 'interview',
    component: () =>
      import(
        /* webpackChunkName: "interview" */ '../views/Dashboard/Vacancy/Interview'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        tipo: route.params.tipo,
        participant: route.params.participant,
        vacancy: route.params.vacancy
      };
    }
  },
  {
    path: '/painel/finalizacao-da-vaga/:id',
    name: 'finalizing-vacancy',
    component: () =>
      import(
        /* webpackChunkName: "finalizing-vacancy" */ '../views/Dashboard/Vacancy/FinalizingVacancy'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/vagas/:id/editar-vagas/',
    name: 'edit-vacancy',
    component: () =>
      import(
        /* webpackChunkName: "edit-vacancy" */ '../views/Dashboard/Vacancy/Edit'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id),
        userId: parseInt(route.params.userId)
      };
    }
  },
  {
    path: '/painel/vagas/:id/ver-vaga/',
    name: 'view-vacancy',
    component: () =>
      import(
        /* webpackChunkName: "view-vacancy" */ '../views/Dashboard/Vacancy/View'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id),
        userId: parseInt(route.params.userId)
      };
    }
  },
  {
    path: '/painel/vagas/:id/participantes-aprovados/',
    name: 'list-participants-approved',
    component: () =>
      import(
        /* webpackChunkName: "list-participants-approved" */ '../views/Dashboard/Vacancy/ParticipantsApproved'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/vagas',
    name: 'list-vacancies',
    component: () =>
      import(
        /* webpackChunkName: "list-vacancies" */ '../views/Dashboard/Vacancy/Index'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/buscar-vagas',
    name: 'search-vacancies',
    component: () =>
      import(
        /* webpackChunkName: "search-vacancies" */ '../views/Dashboard/Participant/Vacancy/Index'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/vaga/:id',
    name: 'vacancy-description',
    component: () =>
      import(
        /* webpackChunkName: "vacancy-description" */ '../views/Dashboard/Participant/Vacancy/Single'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id)
      };
    }
  },
  {
    path: '/painel/minhas-vagas',
    name: 'my-vacancies',
    component: () =>
      import(
        /* webpackChunkName: "my-vacancies" */ '../views/Dashboard/Participant/Vacancy/MyVacancies'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/participantes/buscar',
    name: 'search-participants',
    component: () =>
      import(
        /* webpackChunkName: "search-participants" */ '../views/Dashboard/Participant/Search'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/participantes/:id/editar',
    name: 'edit-participant',
    component: () =>
      import(
        /* webpackChunkName: "edit-participant" */ '../views/Dashboard/Participant/Edit'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id)
      };
    }
  },
  {
    path: '/painel/usuarios',
    name: 'user',
    component: () =>
      import(
        /* webpackChunkName: "list-users" */ '../views/Dashboard/User/Index'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/usuarios/criar',
    name: 'user-create',
    component: () =>
      import(
        /* webpackChunkName: "create-user" */ '../views/Dashboard/User/Create'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/usuarios/editar',
    name: 'user-edit',
    component: () =>
      import(/* webpackChunkName: "talent" */ '../views/Dashboard/User/Edit'),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/meu-acesso',
    name: 'my-access',
    component: () =>
      import(
        /* webpackChunkName: "my-access" */ '../views/Dashboard/Profile/Index'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/meu-perfil',
    name: 'my-profile',
    component: () =>
      import(
        /* webpackChunkName: "my-profile" */ '../views/Dashboard/Profile/MyProfile'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/meu-endereco',
    name: 'my-address',
    component: () =>
      import(
        /* webpackChunkName: "my-address" */ '../views/Dashboard/Profile/Address'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/meu-curriculo-completo',
    name: 'my-complete-curriculum',
    component: () =>
      import(
        /* webpackChunkName: "my-complete-curriculum" */ '../views/Dashboard/Profile/Curriculum'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/meu-curriculo',
    name: 'my-curriculum',
    component: () =>
      import(
        /* webpackChunkName: "my-curriculum" */ '../views/Dashboard/Profile/Curriculum/Index'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/meu-curriculo/formacao-academica/criar',
    name: 'create-academic-background',
    component: () =>
      import(
        /* webpackChunkName: "create-academic-background" */ '../views/Dashboard/Profile/Curriculum/AcademicBackgroundCreate'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/meu-curriculo/formacao-academica/:id/editar',
    name: 'edit-academic-background',
    component: () =>
      import(
        /* webpackChunkName: "edit-academic-background" */ '../views/Dashboard/Profile/Curriculum/AcademicBackgroundEdit'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id)
      };
    }
  },
  {
    path: '/painel/meu-curriculo/curso-complementar/criar',
    name: 'create-complementary-course',
    component: () =>
      import(
        /* webpackChunkName: "create-complementary-course" */ '../views/Dashboard/Profile/Curriculum/ComplementaryCourseCreate'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/meu-curriculo/curso-complementar/:id/editar',
    name: 'edit-complementary-course',
    component: () =>
      import(
        /* webpackChunkName: "edit-complementary-course" */ '../views/Dashboard/Profile/Curriculum/ComplementaryCourseEdit'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id)
      };
    }
  },
  {
    path: '/painel/meu-curriculo/experiencia/criar',
    name: 'create-experience',
    component: () =>
      import(
        /* webpackChunkName: "create-experience" */ '../views/Dashboard/Profile/Curriculum/ExperienceCreate'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/meu-curriculo/experiencia/:id/editar',
    name: 'edit-experience',
    component: () =>
      import(
        /* webpackChunkName: "edit-experience" */ '../views/Dashboard/Profile/Curriculum/ExperienceEdit'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id)
      };
    }
  },
  {
    path: '/painel/meu-curriculo/areas-de-interesse',
    name: 'edit-interest-areas',
    component: () =>
      import(
        /* webpackChunkName: "edit-interest-areas" */ '../views/Dashboard/Profile/Curriculum/InterestAreasEdit'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/participantes/:participant/curriculo',
    name: 'participant-curriculum',
    component: () =>
      import(
        /* webpackChunkName: "participant-curriculum" */ '../views/Dashboard/Participant/Curriculum/Index'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        participant: parseInt(route.params.participant)
      };
    }
  },
  {
    path:
      '/painel/participantes/:participant/curriculo/formacao-academica/criar',
    name: 'create-participant-academic-background',
    component: () =>
      import(
        /* webpackChunkName: "create-participant-academic-background" */ '../views/Dashboard/Participant/Curriculum/AcademicBackgroundCreate'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        participant: parseInt(route.params.participant)
      };
    }
  },
  {
    path:
      '/painel/participantes/:participant/curriculo/formacao-academica/:id/editar',
    name: 'edit-participant-academic-background',
    component: () =>
      import(
        /* webpackChunkName: "edit-participant-academic-background" */ '../views/Dashboard/Participant/Curriculum/AcademicBackgroundEdit'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        participant: parseInt(route.params.participant),
        id: parseInt(route.params.id)
      };
    }
  },
  {
    path:
      '/painel/participantes/:participant/curriculo/curso-complementar/criar',
    name: 'create-participant-complementary-course',
    component: () =>
      import(
        /* webpackChunkName: "create-participant-complementary-course" */ '../views/Dashboard/Participant/Curriculum/ComplementaryCourseCreate'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        participant: parseInt(route.params.participant)
      };
    }
  },
  {
    path:
      '/painel/participantes/:participant/curriculo/curso-complementar/:id/editar',
    name: 'edit-participant-complementary-course',
    component: () =>
      import(
        /* webpackChunkName: "edit-participant-complementary-course" */ '../views/Dashboard/Participant/Curriculum/ComplementaryCourseEdit'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        participant: parseInt(route.params.participant),
        id: parseInt(route.params.id)
      };
    }
  },
  {
    path: '/painel/participantes/:participant/curriculo/experiencia/criar',
    name: 'create-participant-experience',
    component: () =>
      import(
        /* webpackChunkName: "create-participant-experience" */ '../views/Dashboard/Participant/Curriculum/ExperienceCreate'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        participant: parseInt(route.params.participant)
      };
    }
  },
  {
    path: '/painel/participantes/:participant/curriculo/experiencia/:id/editar',
    name: 'edit-participant-experience',
    component: () =>
      import(
        /* webpackChunkName: "edit-participant-experience" */ '../views/Dashboard/Participant/Curriculum/ExperienceEdit'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        participant: parseInt(route.params.participant),
        id: parseInt(route.params.id)
      };
    }
  },
  {
    path:
      '/painel/participantes/:participant/curriculo/areas-de-interesse/editar',
    name: 'edit-participant-interest-areas',
    component: () =>
      import(
        /* webpackChunkName: "edit-participant-interest-areas" */ '../views/Dashboard/Participant/Curriculum/InterestAreasEdit'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        participant: parseInt(route.params.participant)
      };
    }
  },
  {
    path: '/aceitar-termo',
    name: 'accept-term',
    component: () =>
      import(
        /* webpackChunkName: "accept-term" */ '../views/Dashboard/Term/AcceptTerm'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: false
    }
  },
  {
    path: '/painel/lista-interessados',
    name: 'action-plan',
    component: () =>
      import(
        /* webpackChunkName: "action-plan" */ '../views/Dashboard/ActionPlan/Index'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/lista-interessados/interesses/:id',
    name: 'interests-list',
    component: () =>
      import(
        /* webpackChunkName: "action-plan" */ '../views/Dashboard/ActionPlan/InterestsList'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id)
      };
    }
  },
  {
    path: '/painel/lista-interessados/prospectar/:id',
    name: 'prospect',
    component: () =>
      import(
        /* webpackChunkName: "action-plan" */ '../views/Dashboard/ActionPlan/Prospect.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/lista-interessados/sugestoes/:id/:participant',
    name: 'sugestions',
    component: () =>
      import(
        /* webpackChunkName: "action-plan" */ '../views/Dashboard/ActionPlan/Sugestions.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  //Campanhas de publicidades
  {
    path: '/painel/ads',
    name: 'list-ads',
    component: () =>
      import(
        /* webpackChunkName: "list-ads" */ '../views/Dashboard/Ads/Index.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/ads/criar',
    name: 'create-banner',
    component: () =>
      import(
        /* webpackChunkName: "create-banner" */ '../views/Dashboard/Ads/Create.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  {
    path: '/painel/ads/:id/editar',
    name: 'edit-ads',
    component: () =>
      import(
        /* webpackChunkName: "edit-associate" */ '../views/Dashboard/Ads/Edit.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    },
    props(route) {
      return {
        id: parseInt(route.params.id)
      };
    }
  },
  // Notifications
  {
    path: '/painel/notification',
    name: 'notifications',
    component: () =>
      import(
        /* webpackChunkName: "list-ads" */ '../views/Dashboard/Notifications/Index.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },
  //Favorites
  {
    path: '/painel/favoritos',
    name: 'favorites',
    component: () =>
      import(
        /* webpackChunkName: "list-ads" */ '../views/Dashboard/Favorites/Index.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresAcceptance: true
    }
  },

  ...associate,
  ...term,
  ...regional
];

export default dashboardRoutes;
