import axios from '@/utils/axios';

const initialState = {
  items: [],
  columns: [
    {
      width: 'w-1/12',
      label: '#',
      name: 'id'
    },
    {
      width: 'w-3/12',
      label: 'Criador',
      name: 'user_id'
    },
    {
      width: 'w-5/12',
      label: 'Título',
      name: 'title'
    },
    {
      width: 'w-3/12',
      label: 'Versão',
      name: 'version'
    }
  ],
  tableData: {
    draw: 0,
    length: 10,
    search: '',
    column: 0,
    direction: -1
  },
  pagination: {
    current_page: null,
    from: null,
    links: [],
    last_page: null,
    per_page: null,
    to: null,
    total: null
  }
};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    tableDataAttributes(state) {
      return state.tableData;
    },
    getSortedColumn(state) {
      return state.tableData.column;
    },
    getSortedDirection(state) {
      return state.tableData.direction;
    }
  },

  mutations: {
    setTerms(state, terms) {
      state.items = terms;
    },
    setPaginationLinks(state, links) {
      state.pagination.links = links;
    },
    setPaginationAttributes(state, meta) {
      Object.assign(state.pagination, meta);
    },
    deleteTerm(state, term) {
      state.items = state.items.filter(item => item !== term);
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
    incrementDraw(state) {
      state.tableData.draw++;
    },
    setAmount(state, amount) {
      state.tableData.length = amount;
    },
    setSearch(state, search) {
      state.tableData.search = search;
    },
    setSortColumn(state, column) {
      state.tableData.column = column;
    },
    setSortDirection(state, direction) {
      state.tableData.direction = direction;
    }
  },

  actions: {
    async fetchTerms({ commit, getters }, endpoint = '/termos') {
      await new Promise((resolve, reject) => {
        commit('incrementDraw');

        axios
          .get(endpoint, {
            params: getters.tableDataAttributes
          })
          .then(({ data: response }) => {
            if (getters.tableDataAttributes.draw === parseInt(response.draw)) {
              const terms = response.data;

              const links = response.meta.links.map(link => {
                let url = link.url ? new URL(link.url) : null;

                return {
                  url: url ? url.pathname.substring(4) + url.search : null,
                  label: link.label,
                  active: link.active,
                  disabled: link.url == null || link.active ? 'disabled' : null
                };
              });

              commit('setTerms', terms);
              commit('setPaginationAttributes', response.meta);
              commit('setPaginationLinks', links);
            }

            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    async deleteTerm({ commit }, term) {
      await new Promise((resolve, reject) => {
        axios
          .delete('/termos/' + term.id)
          .then(response => {
            commit('deleteTerm', term);

            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    sort({ commit, getters }, column) {
      if (getters.getSortedColumn !== column) {
        commit('setSortDirection', -1);
      } else {
        commit('setSortDirection', getters.getSortedDirection * -1);
      }

      commit('setSortColumn', column);
    }
  }
};
