import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

import appRoutes from './app';
import dashboardRoutes from './dashboard';

Vue.use(VueRouter);

const routes = [...appRoutes, ...dashboardRoutes];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAcceptance = to.matched.some(
    record => record.meta.requiresAcceptance
  );

  if (requiresAcceptance && !store.getters['auth/acceptedCurrentTerm']) {
    next({ name: 'accept-term' });
  }

  if (requiresAuth && !store.getters['auth/authenticated']) {
    next({
      name: 'login',
      query: {
        redirect: to.fullPath
      }
    });
  }

  if (
    (store.getters['auth/acceptedCurrentTerm'] && to.name === 'accept-term') ||
    (store.getters['auth/authenticated'] && to.name === 'login')
  ) {
    next({ name: 'search' });
  }

  next();
});

export default router;
