import axios from '../../utils/axios';

export default {
  namespaced: true,

  state: {
    acceptedCurrentTerm: false,
    isAvailable: false,
    authenticated: false,
    user: null,
    syndicate: null,
    associate: null,
    permissions: []
  },

  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    isAvailable(state) {
      return state.isAvailable;
    },
    user(state) {
      return state.user;
    },
    syndicate(state) {
      return state.syndicate;
    },
    associate(state) {
      return state.associate;
    },
    acceptedCurrentTerm(state) {
      return state.acceptedCurrentTerm;
    },
    permissions(state) {
      return state.permissions;
    }
  },

  mutations: {
    SET_AUTHENTICATED(state, authenticated) {
      state.authenticated = authenticated;
    },
    SET_IS_AVAILABLE(state, isAvailable) {
      state.isAvailable = isAvailable;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_SYNDICATE(state, syndicate) {
      state.syndicate = syndicate;
    },
    SET_ASSOCIATE(state, associate) {
      state.associate = associate;
    },
    SET_ACCEPTED_CURRENT_TERM(state, acceptedCurrentTerm) {
      state.acceptedCurrentTerm = acceptedCurrentTerm;
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    }
  },

  actions: {
    login({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios.get('/sanctum/csrf-cookie').then(() => {
          axios
            .post('/api/login', credentials)
            .then(({ data }) => {
              commit(
                'SET_ACCEPTED_CURRENT_TERM',
                data.data.acceptedCurrentTerm
              );
              commit('SET_IS_AVAILABLE', data.data.isAvailable);
              commit('SET_AUTHENTICATED', true);
              commit('SET_USER', data.data.user);
              commit('SET_SYNDICATE', data.data.syndicate);
              commit('SET_ASSOCIATE', data.data.associate);
              commit('SET_PERMISSIONS', data.data.permissions);

              resolve(data);
            })
            .catch(error => {
              reject(error);
            });
        });
      });
    },
    authByCourse({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios.get('/sanctum/csrf-cookie').then(() => {
          axios
            .post('api/login/auth/participant', credentials)
            .then(({ data }) => {
              commit(
                'SET_ACCEPTED_CURRENT_TERM',
                data.data.acceptedCurrentTerm
              );
              commit('SET_IS_AVAILABLE', data.data.isAvailable);
              commit('SET_AUTHENTICATED', true);
              commit('SET_USER', data.data.user);
              commit('SET_SYNDICATE', data.data.syndicate);
              commit('SET_ASSOCIATE', data.data.associate);
              commit('SET_PERMISSIONS', data.data.permissions);

              resolve(data);
            })
            .catch(error => {
              reject(error);
            });
        });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/logout')
          .then(response => {
            commit('SET_ACCEPTED_CURRENT_TERM', false);
            commit('SET_IS_AVAILABLE', false);
            commit('SET_AUTHENTICATED', false);
            commit('SET_USER', null);
            commit('SET_SYNDICATE', null);
            commit('SET_ASSOCIATE', null);
            commit('SET_PERMISSIONS', []);

            resolve(response);
          })
          .catch(error => {
            commit('SET_ACCEPTED_CURRENT_TERM', false);
            commit('SET_IS_AVAILABLE', false);
            commit('SET_AUTHENTICATED', false);
            commit('SET_USER', null);
            commit('SET_SYNDICATE', null);
            commit('SET_ASSOCIATE', null);
            commit('SET_PERMISSIONS', []);

            reject(error);
          });
      });
    },
    user({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/usuario')
          .then(({ data }) => {
            commit('SET_ACCEPTED_CURRENT_TERM', data.data.acceptedCurrentTerm);
            commit('SET_IS_AVAILABLE', data.data.isAvailable);
            commit('SET_AUTHENTICATED', true);
            commit('SET_USER', data.data.user);
            commit('SET_SYNDICATE', data.data.syndicate);
            commit('SET_ASSOCIATE', data.data.associate);
            commit('SET_PERMISSIONS', data.data.permissions);

            resolve(data);
          })
          .catch(error => {
            commit('SET_ACCEPTED_CURRENT_TERM', false);
            commit('SET_IS_AVAILABLE', false);
            commit('SET_AUTHENTICATED', false);
            commit('SET_USER', null);
            commit('SET_SYNDICATE', null);
            commit('SET_ASSOCIATE', null);
            commit('SET_PERMISSIONS', []);

            reject(error);
          });
      });
    },
    recoveryPassword(context, email) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/esqueci-minha-senha', {
            email
          })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    resetPassword(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/redefinir-senha', data)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};
