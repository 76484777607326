import axios from '@/utils/axios';

export default {
  namespaced: true,

  state: {
    participant: null,
    email: null,
    status: null,
    statusParticipant: null
  },

  getters: {
    participant(state) {
      return state.participant;
    },
    email(state) {
      return state.email;
    },
    status(state) {
      return state.status;
    },
    statusParticipant(state) {
      return state.statusParticipant;
    }
  },

  mutations: {
    SET_PARTICIPANT(state, participant) {
      state.participant = participant;
    },
    SET_EMAIL(state, email) {
      state.email = email;
    },
    SET_STATUS(state, status) {
      state.status = status;
    },
    SET_STATUS_PARTICIPANT(state, statusParticipant) {
      state.statusParticipant = statusParticipant;
    }
  },

  actions: {
    registerByCpf({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios.get('/sanctum/csrf-cookie').then(() => {
          axios
            .post('/api/registrar/by/cpf', credentials)
            .then(({ data }) => {
              commit('SET_STATUS_PARTICIPANT', data.data.status);
              commit('SET_PARTICIPANT', data.data.participant);

              resolve(data);
            })
            .catch(error => {
              reject(error);
            });
        });
      });
    },
    register({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios.get('/sanctum/csrf-cookie').then(() => {
          axios
            .post('/api/registrar', credentials)
            .then(({ data }) => {
              commit('SET_PARTICIPANT', data.data.participant);
              commit('SET_STATUS', data.data.status);

              resolve(data);
            })
            .catch(error => {
              reject(error);
            });
        });
      });
    },

    registerConfirmation(context, credentials) {
      return new Promise((resolve, reject) => {
        axios.get('/sanctum/csrf-cookie').then(() => {
          axios
            .post('/api/registrar/confirmacao', credentials)
            .then(({ data }) => {
              resolve(data);
            })
            .catch(error => {
              reject(error);
            });
        });
      });
    }
  }
};
