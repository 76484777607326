const appRoutes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/doubts',
    name: 'doubts',
    component: () => import(/* webpackChunkName: "home" */ '../views/Doubts.vue')
  },
  {
    path: '/sobre',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/como-funciona',
    name: 'how-it-works',
    component: () =>
      import(/* webpackChunkName: "how-it-works" */ '../views/HowItWorks.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/quero-participar',
    name: 'register',
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/quero-participar/confirmacao',
    name: 'register-confirmation',
    component: () =>
      import(
        /* webpackChunkName: "register-confirmation" */ '../views/RegisterConfirmation.vue'
      )
  },
  {
    path: '/recuperar-senha',
    name: 'recovery',
    component: () =>
      import(/* webpackChunkName: "how-it-works" */ '../views/Recovery.vue')
  },
  {
    path: '/redefinir-senha/:token',
    name: 'reset',
    component: () =>
      import(/* webpackChunkName: "how-it-works" */ '../views/Reset.vue'),
    props: true
  },
  {
    path: '/painel/descadastrar/:idUser',
    name: 'Unsubscribe',
    component: () =>
      import(/* webpackChunkName: "unsubscribe" */ '../views/Unsubscribe.vue')
  },
  {
    path: '/painel/ativarassociado/:idUser',
    name: 'activate',
    component: () =>
      import(/* webpackChunkName: "unsubscribe" */ '../views/activateassociate.vue')
  },
  {
    path: '/painel/validar-acesso',
    name: 'validate-access',
    component: () =>
      import(
        /* webpackChunkName: "validate-access" */ '../views/Recruiter/Index'
      )
  },
  {
    path: '/painel/cadastrar-empresa',
    name: 'create-access',
    component: () =>
      import(
        /* webpackChunkName: "create-associate" */ '../views/Recruiter/CreateAccess'
      )
  }
];

export default appRoutes;
